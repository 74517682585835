.loginContainer{
    background-color: grey;
}
.loginRow {
    -webkit-box-shadow: 0px 0px 17px -2px rgb(0 0 0 / 15%);
    -moz-box-shadow: 0px 0px 17px -2px rgba(0,0,0,0.15);
    box-shadow: 0px 0px 17px -2px rgb(0 0 0 / 15%);
}
.box-left-login{
    padding: 30px !important;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}
.admin-login-pic{
    max-width:300px!important;
}
.signup-body-box {
padding: 0px;
}
.signin-custom-body {
    border-radius: 0px;
}
.signin-custom-body .card-body{
    padding: 30px;
}

.img-fluid {
    max-width: 100%;
    height: auto;
}
.login-logo {
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
}
.login-logo img {
    width: 200px;
}
.signin-custom-body .login-field-style{
    width:100%;
    text-transform:capitalize;
    font-weight:400;
    margin-bottom:2rem;
}
.signin-custom-body .login-field-style.login-pass{
    margin-bottom:1rem;
}
.signin-custom-body .login-field-style input:-webkit-autofill {
    box-shadow: 0 0 0px 1000px white inset;
    -webkit-box-shadow: 0 0 0px 1000px white inset;
  }
  .signin-custom-body .login-field-style input:hover+fieldset
   {
    border: 1px solid #cccccc;
  }
  .signin-custom-body .login-field-style input:focus+fieldset
   {
    border: 1px solid #267dff;
  }
  



  .login-hd{
      color: #ffffff;
      text-align: center;
  }
.signin-btn{
background-color: #002e7e!important;;
width:100%;
text-transform:capitalize;
font-weight:400;
margin-bottom:2rem;
height: 55px;
}
.signin-btn:hover{
    background-color: #00215a!important;;
    }


    .login-error-txt {
        color:red;
        text-align:center;
    }

/* responsive */
@media only screen and (max-width: 991px){
.loginRow {
    margin-top: 40px !important;
    margin-bottom: 40px !important;
}
}
@media only screen and (max-width: 767px){
    .signin-custom-body .card-body {
        padding: 15px;
    }
    }